/* global FreshworksWidget */
import React, { Suspense, lazy, useEffect } from 'react';

import { ModalSpinner, NotFound } from '@paytheory/pay-theory-ui';

import './App.css';

import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import * as ROUTES from './constants/routes';
import {androidPaths, apiPaths, applePaths, overviewPaths, webPaths} from "./util";

const Font = lazy(() =>
  import ('@paytheory/pay-theory-ui/dist/Font'));

const Markdown = lazy(() =>
    import ('./views/Markdown'));

const App = () => {
  const typekit = process.env.REACT_APP_TYPEKIT;

  useEffect(()=>{
    FreshworksWidget('hide', 'launcher');

    return <div />;
  },[]);

  return (
    <div className="spinner-wrapper">
      <div className="modal-wrapper">
        <Router>
          <Suspense fallback={<ModalSpinner on />}>
            <Routes>
                <Route path="/" element={<Navigate replace to={ROUTES.OVERVIEW} />} />
                <Route
                  exact
                  path={ROUTES.OVERVIEW}
                  element={<Markdown
                      title="Overview"
                      subtitle=""
                      pathObject={overviewPaths}
                      root="OVERVIEW"
                      hashLinks={['h2']}
                  />}
                />
                <Route
                    exact
                    path={ROUTES.OVERVIEW_KEY}
                    element={<Markdown
                        title="Overview"
                        subtitle=""
                        pathObject={overviewPaths}
                        root="OVERVIEW"
                        hashLinks={['h2']}
                    />}
                />
                <Route
                  exact
                  path={ROUTES.WEB}
                  element={<Markdown
                      title="JavaScript SDK"
                      subtitle="Web"
                      pathObject={webPaths}
                      root="WEB"
                      hashLinks={['h2']}
                  />}
                />
                <Route
                    exact
                    path={ROUTES.WEB_KEY}
                    element={<Markdown
                        title="JavaScript SDK"
                        subtitle="Web"
                        pathObject={webPaths}
                        root="WEB"
                        hashLinks={['h2']}
                    />}
                />
                <Route
                    exact
                    path={ROUTES.API}
                    element={<Markdown
                        title="Pay Theory API"
                        subtitle="GraphQL"
                        pathObject={apiPaths}
                        root="API"
                        hashLinks={['h2']}
                    />}
                />
                <Route
                    exact
                    path={ROUTES.API_KEY}
                    element={<Markdown
                        title="Pay Theory API"
                        subtitle="GraphQL"
                        pathObject={apiPaths}
                        root="API"
                        hashLinks={['h2']}
                    />}
                />
                <Route
                    exact
                    path={ROUTES.APPLE}
                    element={<Markdown
                        title="Apple SDK"
                        subtitle="Apple"
                        pathObject={applePaths}
                        root="APPLE"
                        hashLinks={['h2']}
                    />}
                />
                <Route
                    path={ROUTES.APPLE_KEY}
                    element={<Markdown
                        title="Apple SDK"
                        subtitle="Apple"
                        pathObject={applePaths}
                        root="APPLE"
                        hashLinks={['h2']}
                    />}
                />
                <Route
                  exact
                  path={ROUTES.ANDROID}
                  element={<Markdown
                      title="Android SDK"
                      subtitle="Android"
                      pathObject={androidPaths}
                      root="ANDROID"
                      hashLinks={['h2']}
                  />}
                />
                <Route
                    exact
                    path={ROUTES.ANDROID_KEY}
                    element={<Markdown
                        title="Android SDK"
                        subtitle="Android"
                        pathObject={androidPaths}
                        root="ANDROID"
                        hashLinks={['h2']}
                    />}
                />
                <Route path={ROUTES.NOT_FOUND} exact element={<NotFound />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            <Font typekit={typekit} />
          </Suspense>
        </Router>
        <ModalSpinner />
      </div>
    </div>
  );
};

export default App;
