//authentication
export const OVERVIEW = '/overview'
export const OVERVIEW_KEY = '/overview/:key'
export const WEB = '/web'
export const WEB_KEY = '/web/:key'
export const APPLE_KEY = '/apple/:key'
export const APPLE = '/apple'
export const ANDROID = '/android'
export const ANDROID_KEY = '/android/:key'
export const WOOCOMMERCE = '/woocommerce'
export const PAYMENT_PARAMETERS = '/payment-parameters'
export const EMAIL_RECEIPTS = '/email-receipts'
export const API_KEY = '/api/:key'
export const API = '/api'
export const RECURRING = '/recurring'

//error
export const VERIFICATION_FAILED = '/verification-failed'
export const NOT_FOUND = '/not-found'
export const UNAUTHORIZED = '/unauthorized'

