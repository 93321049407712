import * as ROUTES from './constants/routes';
import {Icon} from '@paytheory/pay-theory-ui';

import React from 'react';

export const generateMenu = () =>
{
    return [
        {
            to: ROUTES.OVERVIEW,
            className: 'active',
            tag: 'overview',
            icon: 'asterisk',
            label: 'Overview',
            isCategory: true,
            subContent: [
                {
                    to: `${ROUTES.OVERVIEW}/failed_transactions`,
                    tag: 'failed-transactions',
                    label: 'Failed Transactions',
                },
                {
                    to: `${ROUTES.OVERVIEW}/recurring`,
                    tag: 'recurring-quickstart',
                    label: 'Recurring Payments',
                },
                // {
                //     to: `${ROUTES.OVERVIEW}/card_present`,
                //     tag: 'card-present-quickstart',
                //     label: 'Card Present',
                // },
                {
                    to: `${ROUTES.OVERVIEW}/email_receipts`,
                    tag: 'email-receipts-quickstart',
                    label: 'Email Receipts',
                },
                {
                    to: `${ROUTES.OVERVIEW}/checkout_button`,
                    tag: 'checkout-button-quickstart',
                    label: 'Checkout Button',
                }
            ]
        },
        {
            to: ROUTES.WEB,
            className: 'inactive',
            tag: 'web',
            icon: 'browser',
            label: 'Javascript SDK',
            isCategory: true,
            subContent: [
                {
                    to: `${ROUTES.WEB}/functions`,
                    tag: 'functions',
                    label: 'Functions',
                },
                {
                    to: `${ROUTES.WEB}/hosted_fields`,
                    tag: 'hosted-fields',
                    label: 'Hosted Fields',
                },
                {
                    to: `${ROUTES.WEB}/event_listeners`,
                    tag: 'event-listeners',
                    label: 'Event Listeners',
                },
                {
                    to: `${ROUTES.WEB}/errors`,
                    tag: 'errors',
                    label: 'Errors',
                },
                {
                    to: `${ROUTES.WEB}/checkout_button`,
                    tag: 'checkout-button',
                    label: 'Checkout Button',
                },
                {
                    to: `${ROUTES.WEB}/checkout_qr_code`,
                    tag: 'checkout-qr-code',
                    label: 'Checkout QR Code',
                },
                {
                    to: `${ROUTES.WEB}/deprecations`,
                    tag: 'deprecations',
                    label: 'Deprecations',
                }
            ]
        },
        {
            to: ROUTES.API,
            className: 'inactive',
            tag: 'api',
            icon: 'brackets-curly',
            label: 'API',
            isCategory: true,
            subContent: [
                {
                    to: `${ROUTES.API}/query`,
                    tag: 'api-query',
                    label: 'Query',
                },
                {
                    to: `${ROUTES.API}/transaction`,
                    tag: 'api-transaction',
                    label: 'Transaction',
                },
                {
                    to: `${ROUTES.API}/payor`,
                    tag: 'api-payor',
                    label: 'Payor',
                },
                {
                    to: `${ROUTES.API}/payment_method_token`,
                    tag: 'api-payment-method-token',
                    label: 'Payment Method Token',
                },
                {
                    to: `${ROUTES.API}/settlement`,
                    tag: 'api-settlement',
                    label: 'Settlement',
                },
                {
                    to: `${ROUTES.API}/merchant`,
                    tag: 'api-merchant',
                    label: 'Merchant',
                },
                {
                    to: `${ROUTES.API}/users`,
                    tag: 'api-users',
                    label: 'Users',
                },
                {
                    to: `${ROUTES.API}/recurring`,
                    tag: 'api-recurring',
                    label: 'Recurring',
                },
                {
                    to: `${ROUTES.API}/metadata`,
                    tag: 'api-metadata',
                    label: 'Metadata',
                },
                // {
                //     to: `${ROUTES.API}/card_present`,
                //     tag: 'card-present',
                //     label: 'Card Present',
                // },
                {
                    to: `${ROUTES.API}/payment_links`,
                    tag: 'api-payment-links',
                    label: 'Payment Links'
                }
            ]
        },
        {
            to: ROUTES.ANDROID,
            className: 'inactive',
            tag: 'android',
            icon: 'android',
            iconBrand: true,
            label: 'Android SDK',
            isCategory: true,
            subContent: [
                // {
                //     to: `${ROUTES.ANDROID}/inputs`,
                //     tag: 'android-inputs',
                //     label: 'Inputs',
                // },
                {
                    to: `${ROUTES.ANDROID}/functions`,
                    tag: 'android-functions',
                    label: 'Functions',
                },
                {
                    to: `${ROUTES.ANDROID}/handlers`,
                    tag: 'android-handlers',
                    label: 'Handlers',
                },
                // {
                //     to: `${ROUTES.ANDROID}/state`,
                //     tag: 'android-state',
                //     label: 'State',
                // }
            ]
        },
        {
            to: ROUTES.APPLE,
            className: 'inactive',
            tag: 'apple',
            icon: 'apple',
            iconBrand: true,
            label: 'Apple SDK',
            isCategory: true,
            subContent: [
                {
                    to: `${ROUTES.APPLE}/inputs`,
                    tag: 'apple-inputs',
                    label: 'Inputs',
                },
                {
                    to: `${ROUTES.APPLE}/functions`,
                    tag: 'apple-functions',
                    label: 'Functions',
                },
                {
                    to: `${ROUTES.APPLE}/completion_handler`,
                    tag: 'apple-completion-handler',
                    label: 'Completion Handler',
                },
                {
                    to: `${ROUTES.APPLE}/state`,
                    tag: 'apple-state',
                    label: 'State',
                }
            ]
        },
    ];
};

const flatten = (text, child) =>
{
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text);
};

export const hashLinkRenderer = ({children, level}) =>
{
    const childrenArr = React.Children.toArray(children);
    const text = childrenArr.reduce(flatten, '');
    const slug = text.toLowerCase().replace(/\./g, '').replace(/\W/g, '-');
    const scrollTo = window.location.hash.replace('#', '') === slug;
    const header = [
        React.createElement('h' + level, {key: slug, id: slug}, children)
    ];

    if (scrollTo)
    {
        setTimeout(() =>
        {
            const element = document.getElementById(slug);
            element.scrollIntoView();
        }, 100);
    }

    const result = (<div className={'header-wrapper'} onClick={() =>
    {
        window.location.hash = slug;
        const element = document.getElementById(slug);
        element.scrollIntoView();
    }}>
        <Icon name="link" label="header-link"/>
        {header}
    </div>);

    return result;
};

export const getMarkdownText = async (filePath) =>
{
    const response = await window.fetch(filePath);
    if (response.ok)
    {
        return response.text();
    }
    return false;
};


const PARTNER_TAG = 'PARTNER_NAME';
const STAGE_TAG = 'STAGE';

const replaceTag = (text, tag, replacement) => text.replace(new RegExp(`${tag}`, 'g'), replacement);

export const replaceAllTags = (text) =>
{
    let working = text;
    working = replaceTag(working, PARTNER_TAG, `${process.env.REACT_APP_PARTNER}${process.env.REACT_APP_MODE}`);
    working = replaceTag(working, STAGE_TAG, process.env.REACT_APP_STAGE);
    return working;
};

export const applePaths = {
    state: 'STATE',
    inputs: 'INPUTS',
    functions: 'FUNCTIONS',
    completion_handler: 'COMPLETION_HANDLER'
};

export const androidPaths = {
    functions: 'FUNCTIONS',
    handlers: 'HANDLERS'
};

export const apiPaths = {
    query: 'QUERY',
    settlement: 'SETTLEMENT',
    transaction: 'TRANSACTION',
    // dispute: 'DISPUTE',
    main: 'MAIN',
    payor: 'PAYOR',
    payment_method_token: 'PAYMENT_METHOD_TOKEN',
    recurring: 'RECURRING',
    metadata: 'METADATA',
    card_present: 'CARD_PRESENT',
    payment_links: 'PAYMENT_LINKS',
    merchant: 'MERCHANT',
    users: 'USERS'
};

export const overviewPaths = {
    main: 'MAIN',
    card_present: 'CARD_PRESENT',
    email_receipts: 'EMAIL_RECEIPTS',
    recurring: 'RECURRING',
    payment_parameters: 'PAYMENT_PARAMETERS',
    checkout_button: 'CHECKOUT_BUTTON',
    failed_transactions: 'FAILED_TRANSACTIONS',
};

export const webPaths = {
    main: 'MAIN',
    event_listeners: 'EVENT_LISTENERS',
    functions: 'FUNCTIONS',
    hosted_fields: 'HOSTED_FIELDS',
    deprecations: 'DEPRECATED',
    checkout_button: 'PAYMENT_BUTTON',
    checkout_qr_code: 'PAYMENT_QR_CODE',
    errors: 'ERRORS',
};
